import NextLink from 'next/link';
import { Box, Flex, Container, Text, Stack, Link } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';
import { Button, IconButton } from '@chakra-ui/button';
import { Collapse } from '@chakra-ui/transition';
import { Icon } from '@chakra-ui/icon';
import { Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/popover';
import { useColorModeValue } from '@chakra-ui/color-mode';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

import {
  NavItem,
  PageGeneral,
  PageUpcomingEvent,
} from '@/modules/home/homeEntity';
import { images } from '@/shared/assets';
import { ApplyLink } from '@/uikit/component/ApplyLink';
import { Show, useDisclosure } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment-timezone';
import { useSearchParams } from 'next/navigation';

const DesktopNav = ({ navItems }: { navItems: Array<NavItem> }) => {
  const linkColor = useColorModeValue('gray.600', 'gray.200');
  const linkHoverColor = useColorModeValue('gray.800', 'teal.500');
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? '#'}
                isExternal={navItem.isExternal || false}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow="xl"
                bg={popoverContentBgColor}
                p={4}
                rounded="xl"
                minW="sm">
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('teal.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'teal.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'teal.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({ navItems }: { navItems: Array<NavItem> }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href, isExternal }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        isExternal={isExternal || false}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text color={useColorModeValue('gray.600', 'gray.200')}>{label}</Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

function PageHeader({
  data,
  events,
}: {
  data: PageGeneral;
  events: PageUpcomingEvent;
}) {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const threshold = 200; // Set the threshold in pixels
    const onScroll = () => {
      if (window.scrollY >= threshold) {
        if (isVisible) setIsVisible(false);
      } else {
        if (!isVisible) setIsVisible(true);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isVisible]);

  const params = useSearchParams();

  function appendLinkWithParams(
    link: string,
    params: URLSearchParams | null,
  ): string {
    let updatedLink = link;

    // Check if the link already has parameters
    let separator: string = link.includes('?') ? '&' : '?';

    if (!params) return updatedLink;

    // Iterate through each parameters and append it to the link
    for (let key of params.keys()) {
      updatedLink += `${separator}${key}=${params.get(key)}`;
      separator = '&';
    }

    return updatedLink;
  }

  return (
    <Box
      pos="sticky"
      zIndex="sticky"
      top={0}
      shadow={useColorModeValue(
        '0px 6px 20px 4px rgba(113, 130, 164, 0.08)',
        '0px 6px 20px 4px rgba(68, 78, 98, 0.08)',
      )}
      sx={{
        bg: 'light-seagreen.110',
      }}>
      <Container maxW={'7xl'}>
        <Flex
          color={useColorModeValue('gray.600', 'white')}
          minH="60px"
          py={{ base: 2 }}
          align="center">
          <Stack
            flex={1}
            direction="row"
            justify={{ base: 'center', md: 'start' }}
            alignItems="center"
            spacing={10}>
            <NextLink href="/" prefetch={false}>
              <Image
                alt="REA Logo"
                src={images('rea_sa')}
                height={9}
                width="auto"
                htmlHeight="36"
                htmlWidth="112"
              />
            </NextLink>
            {data.navigation.length > 0 && (
              <Show above="md">
                <Stack direction="row" spacing={10}>
                  {data.navigation.map((item, i) => (
                    <NextLink key={i} href={item.link}>
                      <Text fontSize={'sm'} fontWeight="bold">
                        {item.label}
                      </Text>
                    </NextLink>
                  ))}
                </Stack>
              </Show>
            )}

            {events.events.length > 0 && (
              <Button
                colorScheme="blue"
                bgColor="blue.500"
                px="5"
                color="white"
                display={{ base: 'none', md: 'inline-flex' }}
                as="a"
                href="#events">
                Join Our Events!
              </Button>
            )}
          </Stack>
        </Flex>
      </Container>
      {/* <Link
        href="https://discord.gg/XAYeAsXXgC"
        target="_blank"
        _hover={{
          textDecoration: 'none',
        }}>
        <Flex
          textAlign="center"
          _hover={{
            bg: '#146765',
          }}
          sx={{ bg: '#156767' }}
          align="center"
          mx="auto"
          py="3">
          <Container maxW="container.lg" centerContent>
            <Stack direction={['column', 'row']} alignItems='center'>
              <Show above='md'>
                <Box mr="3" textAlign='center' fontSize='sm'>
                  Join the REA+ Discord Community for the latest AI trends, webinars, and
                  vibrant discussions with fellow enthusiasts! 🚀✨
                </Box>
                <Button
                  background='white'
                  color='black'
                  size='sm'
                  minWidth='min-content'
                  _hover={{ bg: "white" }}
                >
                  Join Now!
                </Button>
              </Show>
              <Show below='md'>
              <Button
                  background='white'
                  color='black'
                  size='sm'
                  minWidth='min-content'
                  _hover={{ bg: "white" }}
                >
                  Join REA+ Discord Community Now!
                </Button>
              </Show>
            </Stack>
          </Container>
        </Flex>
      </Link> */}

      {/* <Collapse in={isOpen} animateOpacity>
        <MobileNav navItems={data.navigation} />
      </Collapse> */}
    </Box>
  );
}

const countdownTimer = (toDate) => {
  const inputDate = toDate; //'23:59;31-December-2024'; // Replace this with the desired date in the custom format
  const endDate = parseDate(inputDate);
  const { months, days, hours, minutes, seconds } = useCountdown(endDate);

  return (
    <Flex
      borderLeftRadius="30"
      borderRightRadius="30"
      textAlign="center"
      sx={{ bg: '#ffffff', color: '#cd1924' }}
      align="center"
      mx="auto"
      py="3">
      <Container maxW="container.lg">
        <Box fontWeight="bold">
          {months} Months : {days} Days : {hours} Hours
        </Box>
      </Container>
    </Flex>
  );
};

const StickyAnnouncement = () => {
  const inputDate = '10:30;03-July-2023'; // Replace this with the desired date in the custom format
  const endDate = parseDate(inputDate);
  const { days, hours, minutes, seconds } = useCountdown(endDate);

  return (
    <Link
      href="https://google.com"
      target="_blank"
      _hover={{
        textDecoration: 'none',
      }}>
      <Flex
        textAlign="center"
        _hover={{
          bg: '#f77183',
        }}
        sx={{ bg: '#EB344C' }}
        align="center"
        mx="auto"
        py="3">
        <Container maxW="container.lg">
          <Box mr="3">Join Our Next AI Workshop With Pahlevi Auliya!</Box>
          <Box>
            Will be hold in: {days} Days {hours} Hours {minutes} Minutes{' '}
            {seconds} Seconds
          </Box>
        </Container>
      </Flex>
    </Link>
  );
};

const parseDate = (input, timezone = 'Asia/Jakarta') => {
  const format = 'HH:mm;DD-MMMM-YYYY';
  const dateString = moment.tz(input, format, timezone).toISOString();
  return new Date(dateString);
};

export default StickyAnnouncement;

const useCountdown = (endDate) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const countdown = () => {
      const now = new Date().getTime();
      const timeDifference = endDate.getTime() - now;

      if (timeDifference >= 0) {
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const days = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24),
        );
        const hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
        );
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60),
        );
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeLeft({ months, days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
      }
    };

    const interval = setInterval(countdown, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endDate]);

  return timeLeft;
};

export { PageHeader, countdownTimer };
